import { GetCompaniesParams, GetCompaniesResponse } from '~/feature/companies/types'
import { buildQueryString } from '@IJGN/potential'
import { getApiClient } from '~/lib/apiClientSingleton'

const getCompanies = async (params: GetCompaniesParams): Promise<GetCompaniesResponse> => {
  const queryString = buildQueryString(params)
  const apiClient = getApiClient()
  return await apiClient.fetchFromAPI<Promise<GetCompaniesResponse>>({
    endpoint: '/companies',
    queryString: queryString,
    options: apiClient.getRequestOptions(),
  })
}

export default getCompanies
